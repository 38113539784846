@font-face {
	font-family: 'RoslindaleDisplayCondensed-Bold-Testing';
	src: url('fonts/roslindale/RoslindaleDisplayCondensed-Bold-Testing.eot')
		format('eot'); /* IE9 Compat Modes */
	src: url('fonts/roslindale/RoslindaleDisplayCondensed-Bold-Testing.woff')
			format('woff'),
		/* Modern Browsers */
			url('fonts/roslindale/RoslindaleDisplayCondensed-Bold-Testing.woff2')
			format('woff2'); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SuisseIntl-Bold';
	src: url('fonts/suisse/SuisseIntl-Bold.otf') format('opentype');
	/* Open Type Font */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SuisseIntl-Light';
	src: url('fonts/suisse/SuisseIntl-Light.otf') format('opentype');
	/* Open Type Font */
	font-weight: normal;
	font-style: normal;
}

.App {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	min-height: 100vh;
}

#container {
	padding: 48px 16px;
}

h1 {
	font-family: RoslindaleDisplayCondensed-Bold-Testing;
	font-size: 72px;
	color: #292929;
	letter-spacing: -0.025em;
	margin: 0;
}

subtitle {
	font-family: SuisseIntl-Bold;
	font-size: 16px;
	color: #292929;
	text-transform: uppercase;
	letter-spacing: -0.025em;
	margin: 0;
}

.social {
	font-family: 'SuisseIntl-Bold';
	font-size: 16px;
	color: #292929;
	max-width: 320px;
	letter-spacing: -0.025em;
}

p {
	font-family: SuisseIntl-Light;
	font-size: 16px;
	color: #292929;
	max-width: 320px;
	letter-spacing: -0.025em;
	margin: 0;
}

p:last-child {
	margin-bottom: 48px;
}

a {
	text-decoration: none;
	color: #292929;
}
